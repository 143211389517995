//
// 3rd-party config
//

// Bootstrap Maxlength
$bootstrap-maxlength-z-index: 1040;

// Daterangepicker
$daterangepicker-ranges-list-height: 260px;
$daterangepicker-ranges-list-width: 150px;
