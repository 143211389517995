@tailwind base;
@tailwind components;
@tailwind utilities;

// Flex Grid styles
.tradeScope-container {
  .tab-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    #tradeScope-tabpane-items.active {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      .trade-scope-grid-items {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        overflow: auto;
        height: 0;
      }
    }

    #tradeScope-tabpane-itemsBudget.active {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      .trade-budget-grid {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        overflow: auto;
        height: 0;
      }
    }

    #tradeScope-tabpane-alternates.active {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      .trade-alternates-grid {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        overflow: auto;
        height: 0;
      }
    }

    #tradeScope-tabpane-clarifications.active {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      .trade-clarifications-grid {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        overflow: auto;
        height: 0;
      }
    }
  }
}

.snapshot-modal-tabs {
  .tab-content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    &>.active {
      display: flex !important;
    }
  }
}

.expense-template-modal-flexgrid {
  .tab-content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    &>.active {
      display: flex !important;
    }
  }
}

.validation-error-highlight {
  background: #ff6666 !important;
}

.new-dashboard-box {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 30px;
}

.dashboard-title {
  color: var(--Gray-1, #21272a);
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 67.2px */
  text-transform: capitalize;
}

.dashboard-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  width: 100%;
}

.dashboard-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
}

.blue-info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border: 1px solid var(--CoolGray-20, #dde1e6);
  background: var(--Dark-Blue, #0074a6);
}

.blue-info-card-container {
  display: flex;
  padding-top: 32px;
  padding-inline: 32px;
  padding-bottom: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}

.info-card-row {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.info-card-row-text {
  color: var(--White, var(--common-white-main, #fff));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.min-w-180 {
  width: 180px;
}

.blue-info-card-title {
  color: var(--White, var(--common-white-main, #fff));
  /* Desk/Headline 2 */
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  /* 35.2px */
}

.primary-border {
  border-width: 1px;
  border-color: var(--primary-color);
}

.gray-text {
  color: var(--gray-text-color);
}

:root {
  --primary-color: #0074a6;
  --secondary-color: #149eda;
  --gray-text-color: #4f4f4f;
}

.card-info-display-title {
  color: var(--primary-color);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.divider {
  width: 100%;
  height: 1px;
  background: var(--divider, rgba(0, 0, 0, 0.12));
}

.request-card-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.card-box {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  width: 100%;
}

.plan-balance-box {
  width: 50%;
  gap: 1rem;
  /* 16px */
}

.hide-on-mobile {
  display: block;
}
.display-on-mobile {
  display: none;
}

.card-large-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 30px 0;
  border-radius: 10px;
  width: 100%;
}

.card-large-button span {
  color: var(--White, var(--common-white-main, #fff));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.card-blue-link {
  display: flex;
  padding: 12px 8px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.card-blue-link span {
  color: var(--primary-color);
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.card-blue-link span:hover {
  color: #149EDA;
}
a {
  color: var(--primary-color); //!important;
  cursor: pointer; //!important;
}
a:hover {
  color: #149EDA; //!important;
}


.blue-title {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: #149EDA;
}




/* Footer (Desktop) */
.footer {
  background-color: #21272A;
  color: #FFFFFF;
  padding: 20px 80px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-app-name-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
  text-align: start;
}

.footer-nav-link {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  margin-left: 24px;
  margin-right: 24px;
  text-decoration: none;
  color: inherit;
}



.news-card {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background-color: #fff;
  padding: 20px;
  width: 100%;
  margin: 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.news-card img {
  width: 100%;
  height: 280px;
  object-fit: cover;
  display: block; /* Remove any space below the image */
}

.news-card h3 {
  margin-bottom: 12px;
  color: var(--Gray-1, #21272A);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 26.4px */
}

.blog-card {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background-color: #fff;
  padding: 20px;
  width: 100%;
  margin: 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.blog-card img {
  width: 100%;
  height: 280px;
  object-fit: cover;
  display: block; /* Remove any space below the image */
}

.blog-card h3 {
  margin-bottom: 12px;
  color: var(--Gray-1, #21272A);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 26.4px */
}

.dashboard-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.bg-primary {
  background-color: var(--primary-color);
}

.news-text {
  color: var(--Gray-1, #21272A);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.claim-text {
  color: var(--Gray-1, #21272A);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.claim-text-italic {
  color: var(--Gray-1, #21272A);
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.direct-debit-italic {
  color: var(--Gray-1, #21272A);
  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
}

.incentive-text {
  color: var(--Gray-1, #21272A);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.incentive-text-italic {
  color: var(--Gray-1, #21272A);
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}


.errors-list {
  margin: 20px;
  padding-top: 10px;
  border: 1px solid red;
  background-color: rgb(255, 232, 232);
  border-radius: 4px;
  font-size: 12px;
}

.errors-list-heading {
  font-size: 13px;
  margin-left: 12px;
}


.publication-text {
  color: var(--Gray-3, var(--Gray-3, #828282));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* Footer (Mobile) */
@media (max-width: 855px) {
  .footer {
    padding: 32px 16px;
    max-width: 100vw;
  }

  .footer-content {
    flex-direction: column;
    justify-content: center;
    gap: 32px;
  }
}

@media (max-width: 600px) {
  .request-card-box {
    flex-direction: column;
  }

  .card-box {
    gap: 30px;
    flex-direction: column;
  }

  .new-dashboard-box {
    flex-direction: column;
  }

  .hide-on-mobile {
    display: none;
  }
  .display-on-mobile {
    display: block;
  }
}



/* Tables */
/* Styles for mobile devices */
@media only screen and (max-width: 720px) {
  .table_box_mobile {
     display: inline;
  }
  .table_box_xl_device {
    display: none;
  }
}

/* Styles for extra large devices */
@media only screen and (min-width: 721px) {
  .table_box_mobile {
    display: none;
  }
  .table_box_xl_device {
      display: inline;
  }
}

/* Default max-width for screens smaller than 990px */
.table_cell_text {
  max-width: 160px;
}

/* For screens between 990px and 1180px (Large screen with NavBar displayed) */
@media screen and (min-width: 990px) and (max-width: 1180px) {
  .table_cell_text {
    max-width: 100px;
  }
}

/* For screens larger than 1180px */
@media screen and (min-width: 1180px) {
  .table_cell_text {
    max-width: 250px;
  }
}

.circle_button_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0074a6;
  border-radius: 9999px;
  cursor: pointer;
  min-width: 43px;
  min-height: 43px;
}

.gray-text {
  color: var(--Gray-3, var(--Gray-3, #828282));
}

.gray-1-text {
  color: var(--Gray-1, var(--Gray-1, #21272A));
}

.mobile_table_button_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
}

.mobile_table_box_row_box {
  display: flex;
  justify-content: start;
}
@media screen and (min-width: 480px) {
  .mobile_table_box_row_box {
    display: flex;
    justify-content: stretch;
  }
}

#kt_app_header {
  border-bottom: 1px solid var(--border-color);
}

.error-help {
  color: #fd397a;
  font-size: 0.75rem;
}

.warning-text {
  color: #fd397a;
}