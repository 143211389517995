#kt_app_sidebar_toggle {
  color: var(--bs-gray-dark);
}

body {
  overflow: hidden;
  height: 100dvh;
  min-width: 320px;
}

.app-root {
  height: 100dvh;
}

.app-page {
  height: 100dvh;
}

.app-wrapper {
  height: 100dvh;
}

@media (max-width: 991.98px) {
  .app-wrapper {
    height: calc(100dvh - var(--bs-app-header-height));
  }
}

.app-sidebar-wrapper {
  max-height: 100%;
  padding-bottom: 30px;
}

.mobileFullWidth {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 991.98px) {
  .mobileFullWidth {
    padding-left: 0;
    padding-right: 0;
  }
  .mobileFullWidth > .table_box_mobile > .dashboard-section {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.mobile-menu {
  width: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.1s ease;

  .menu-item {
    width: 100%;
  }
}

.mobile-menu.mobile-menu-show {
  width: 100%;
  display: flex;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.1s ease;
}